import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import axios from "axios";

import Tags from "./Tags";
import Posts from "./Posts";

const Blog = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [picPosts, setPicPosts] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [limitedArticles, setLimitedArticles] = useState([]);

    const [isThereMore, setIsThereMore] = useState([false]);

    const [numberOfArticles, setNumberOfArticles] = useState(3);

    const [allTags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);

    const [currentApiUrl, setCurrentApiUrl] = useState("");
    const apiLocation = window.location.href;

    const [noArticles, setNoArticles] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [search, setSearchInput] = useState("");

    const detectApiLocation = () => {
        if (apiLocation && apiLocation.includes("blog")) {
            const apiBasePath = apiLocation.split("/blog")[0];
            setCurrentApiUrl(apiBasePath);
        } else {
            setCurrentApiUrl("");
        }
    };

    const detectTags = () => {
        if (apiLocation && apiLocation.includes("tag")) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            setSelectedTags(urlParams.getAll("tag"));
        }
    };

    // get all posts

    const getSetData = (url, setter, data) => {
        axios
            .get(url)
            .then(response => {
                setter(response.data[data]);
            })
            .catch(error => {
                console.log(error);
            });
    };

    // filter logic

    const multiPropsFilter = (articleArray, filters) => {
        let filterArray =
            filters && filters.includes(",") ? filters.split(",") : filters;
        return (
            articleArray &&
            articleArray.filter(
                temp => temp && temp.tags && temp.tags.includes(filterArray)
            )
        );
    };

    const getSearchValue = e => {
        setSearchInput(e.target.value);
        setSelectedTags([]);
    };

    const getTagValue = (name, tagState) => {
        if (selectedTags && tagState) {
            let tagsRemoved =
                selectedTags &&
                selectedTags.filter(temp => temp && !temp.includes(name));
            setSelectedTags(tagsRemoved);
            setSearchInput("");
        } else if (name && !tagState) {
            setSelectedTags([...selectedTags, name]);
            setSearchInput("");
        }
    };

    const filterPosts = () => {
        if (!search && selectedTags) {
            return multiPropsFilter(picPosts, selectedTags) || picPosts;
        } else if (!search && !selectedTags) {
            return picPosts;
        } else {
            let bigSearch = search.toLocaleUpperCase();
            return picPosts.filter(post =>
                post.title.toLocaleUpperCase().includes(bigSearch)
            );
        }
    };

    // is there more to load
    const getIsThereMore = () => {
        let allArticles = !filtered ? picPosts.length : filtered.length;
        return numberOfArticles === allArticles ? false : true;
    };

    // loaded posts limiter

    const getLimitedArticles = () => {
        let tempArticles = !filtered ? picPosts : filtered;
        return tempArticles.slice(0, numberOfArticles);
    };

    const limiter = () => {
        const articlesToLoad = numberOfArticles + 3;
        let allArticles = !filtered ? picPosts.length : filtered.length;
        const newNumberOfArticles =
            allArticles <= articlesToLoad ? allArticles : articlesToLoad;
        setNumberOfArticles(newNumberOfArticles);
    };

    // image url loading

    const addImgUrls = async () => {
        const postsWithUrls = [];
        for (const blogPost of blogPosts) {
            let imgUrl = await axios
                .get(
                    currentApiUrl + "/get-picture-by-id/" + blogPost.picture_id
                )
                .then(response => {
                    return (
                        currentApiUrl +
                        "/pictures/" +
                        response.data.picture.filename
                    );
                })
                .catch(error => {
                    console.log(error);
                });
            postsWithUrls.push({ ...blogPost, ["imgUrl"]: imgUrl });
        }
        setPicPosts(postsWithUrls);
    };

    // observed events

    useEffect(() => {
        setLoading(true);
        getSetData(
            currentApiUrl + "/get-all-articles",
            setBlogPosts,
            "articles"
        );
        getSetData(currentApiUrl + "/get-article-tags", setTags, "tags");
        return () => {};
    }, []);

    useEffect(() => {
        addImgUrls();
        setLoading(false);
    }, [blogPosts]);

    useEffect(() => {
        setFiltered(filterPosts());
        return () => {};
    }, [picPosts, search, selectedTags]);

    useEffect(() => {
        setLimitedArticles(getLimitedArticles());
        setIsThereMore(getIsThereMore());
        return () => {};
    }, [filtered, numberOfArticles, noArticles]);

    useEffect(() => {
        detectApiLocation();
        detectTags();
    }, [location]);

    useEffect(() => {
        if (
            !loading &&
            !filtered.length &&
            (selectedTags.length || search.length)
        ) {
            setNoArticles(true);
        } else {
            setNoArticles(false);
        }
        return () => {};
    }, [filtered]);

    const tagPortal = ReactDOM.createPortal(
        <Tags
            allTags={allTags}
            selectedTags={selectedTags}
            getSearchValue={getSearchValue}
            getTagValue={getTagValue}
            search={search}
            loading={loading}
        />,
        document.getElementById("tags")
    );
    const postPortal = ReactDOM.createPortal(
        <Posts
            blogPosts={limitedArticles}
            loading={loading}
            noArticles={noArticles}
            numberOfArticles={numberOfArticles}
            limiter={limiter}
            isThereMore={isThereMore}
        />,
        document.getElementById("posts")
    );

    return (
        <>
            {tagPortal}
            {postPortal}
        </>
    );
};

if (document.getElementById("blog")) {
    ReactDOM.render(<Blog />, document.getElementById("blog"));
}

export default Blog;
