import React, { useState, useEffect } from "react";

const Tag = ({ selectedTags, tagname, getTagValue }) => {
    const [tagState, setTagState] = useState(false);

    const isSelected = () => {
        if (tagname && selectedTags && selectedTags.includes(tagname)) {
            setTagState(true);
        } else {
            setTagState(false);
        }
    };

    useEffect(() => {
        isSelected();
    }, [selectedTags]);

    return (
        <span
            className={"button" + (tagState ? " active" : "")}
            onMouseUp={() => {
                getTagValue(tagname, tagState);
                setTagState(!tagState);
            }}
        >
            {tagname}
        </span>
    );
};

export default Tag;
