import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import { smooth } from "../Effects/smoothScroll";

const Nav = () => {
    const [isBig, setIsBig] = useState("big");
    const [openHamburger, setOpenHamburger] = useState(false);
    const [currentUrl, setCurrentUrl] = useState("");

    const icon = useRef();
    const menu = useRef();
    const location = window.location.href

    const handleScroll = React.useCallback(event => {
        setIsBig(window.scrollY > 150 ? "small" : "big");
    });

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll, false);
        };
    }, [isBig]);

    const detectLocation = () => {
		if (
			location &&
			location.includes("blog")
		) {
            const basePath = location.split('/blog')[0]
			setCurrentUrl(basePath)
		} else {
			setCurrentUrl("")
        }
	};


    useEffect(() => {
        smooth();
        return;
    }, []);

    useEffect(() => {
        const listener = event => {
            if (
                menu.current.contains(event.target) ||
                !icon.current.contains(event.target)
            ) {
                setTimeout(() => {
                    setOpenHamburger(false);
                }, 150);
            }
        };
        document.addEventListener("mousedown", listener);
    }, []);

    useEffect(() => {
        detectLocation();
        smooth();
    }, [location]);

    return (
        <nav className={isBig}>
            <div
                ref={icon}
                className={openHamburger ? "menu open" : "menu"}
                onClick={() => setOpenHamburger(!openHamburger)}
            >
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <ul
                ref={menu}
                className={openHamburger ? "navtoggle open" : "navtoggle"}
            >
                <li className="hidden">
                    <a href={currentUrl + "/#intro"}></a>
                </li>
                <li>
                    <a href={currentUrl + "/#you"}>You</a>
                </li>
                <li>
                    <a href={currentUrl + "/#us"}>Us</a>
                </li>
                <li>
                    <a href={currentUrl + "/#why"}>Why Most</a>
                </li>
                <li>
                    <a href={"/blog"}>Blog</a>
                </li>
                <li>
                    <a href={currentUrl + "/#contact"}>Contact us</a>
                </li>
            </ul>
        </nav>
    );
};

if (document.getElementById("nav")) {
    ReactDOM.render(<Nav />, document.getElementById("nav"));
}

export default Nav;
