import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import Carousel from "nuka-carousel";

import breakpoints from "../Helpers/breakpoints"

import Most from "../../../resources/media/most.svg";

import Expertise from "../../../resources/media/cards/Expertise.svg";
import Flexibility from "../../../resources/media/cards/Flexibility.svg";
import Sustainability from "../../../resources/media/cards/Sustainability.svg";
import Inclusion from "../../../resources/media/cards/Inclusion.svg";
import Transparency from "../../../resources/media/cards/Transparency.svg";
import Pro_bono from "../../../resources/media/cards/Pro_bono.svg";

import ExpertiseOvr from "../../../resources/media/cards/ExpertiseOvr.svg";
import FlexibilityOvr from "../../../resources/media/cards/FlexibilityOvr.svg";
import SustainabilityOvr from "../../../resources/media/cards/SustainabilityOvr.svg";
import InclusionOvr from "../../../resources/media/cards/InclusionOvr.svg";
import TransparencyOvr from "../../../resources/media/cards/TransparencyOvr.svg";
import Pro_bonoOvr from "../../../resources/media/cards/Pro_bonoOvr.svg";

const Services = () => {
  const [currCar, setCurrCar] = useState({ slideIndex: 0 });
  const numberOfSlides = (6 - 1);

  // slider has buggy scrolling by default,
  // custom function to fix it with useEffect and setInterval

  useEffect(() => {
    const interval = setInterval(() => {
      if (currCar.slideIndex != numberOfSlides) {
        setCurrCar({
          slideIndex: currCar.slideIndex + 1
        })
      } else {
        setCurrCar({
          slideIndex: 0
        })
      }
    }, 10000);
    return () => clearInterval(interval)
  }, [currCar.slideIndex])

  return (
    <>
      <div className="container-fluid">
        <div className="row vcenter">
          <div className="col-md-offset-2 col-md-12 col-sm-12 col-xs-12">
            <img src={Most} alt="Most" className="left" />
          </div>
          <div className="col-md-offset-2 col-md-6 col-sm-12 col-xs-12">
            <h2>What can you <b>expect?</b></h2>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 slide-control">
            <button className="control"
              onClick={(slideIndex) =>
                setCurrCar({
                  slideIndex:
                    currCar.slideIndex != numberOfSlides ? currCar.slideIndex + 1 : 0,
                })
              }
            >
              ❯
            </button>
            <button className="control"
              onClick={(slideIndex) =>
                setCurrCar({
                  slideIndex:
                    currCar.slideIndex != 0 ? currCar.slideIndex - 1 : numberOfSlides,
                })
              }
            >
              ❮
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-offset-2 col-md-12 col-sm-12 col-xs-12">
            <Carousel
              slideIndex={currCar.slideIndex}
              withoutControls={true}
              heightMode={"max"}
              autoplay={false}
              scrollMode={"remainder"}
              slidesToScroll={1}
              slidesToShow={breakpoints()}
              transitionMode={"fade"}
            >
              <div className="card grdwrap" onClick={(slideIndex) => setCurrCar({ slideIndex: 0 })}>
                <div className="inner">
                  <div className="icowrp">
                    <img src={Expertise} alt="Expertise" className="ico" />
                    <img src={ExpertiseOvr} alt="Expertise hover" className="ico ovr" />
                  </div>
                  <h3>Expertise</h3>
                  <p>The founders of MOST branding came from the Marketing Department of the world’s second-largest telecommunication company, and they have worked in different positions at advertising agencies before. Strong project management and convincing creative output guaranteed.</p>
                </div>
              </div>
              <div className="card grdwrap" onClick={(slideIndex) => setCurrCar({ slideIndex: 1 })}>
                <div className="inner">
                  <div className="icowrp">
                    <img src={Flexibility} alt="Flexibility" className="ico" />
                    <img src={FlexibilityOvr} alt="Flexibility hover" className="ico ovr" />
                  </div>
                  <h3>Flexibility</h3>
                  <p>Much more flexible than network agencies: we tailor our team for every project, only involving team members whose participation is imminent. Our network of professionals guarantees fresh ideas and great execution.  </p>
                </div>
              </div>
              <div className="card grdwrap" onClick={(slideIndex) => setCurrCar({ slideIndex: 2 })}>
                <div className="inner">
                  <div className="icowrp">
                    <img src={Sustainability} alt="Sustainability" className="ico" />
                    <img src={SustainabilityOvr} alt="Sustainability hover" className="ico ovr" />
                  </div>
                  <h3>Sustainability</h3>
                  <p>We’re a green agency with near-zero emission. We don’t need and therefore don’t have physical offices; we’ve implemented a “reduced travelling” policy, meaning we’ll stay in touch with you online so we don’t produce carbon by flying, driving or cruising to your HQ.</p>
                </div>
              </div>
              <div className="card grdwrap" onClick={(slideIndex) => setCurrCar({ slideIndex: 3 })}>
                <div className="inner">
                  <div className="icowrp">
                    <img src={Inclusion} alt="Inclusion" className="ico" />
                    <img src={InclusionOvr} alt="Inclusion hover" className="ico ovr" />
                  </div>
                  <h3>Inclusion</h3>
                  <p>The founders come from Russia and Hungary, both spent years abroad, experienced different cultures and our freelancers have plenty of experiences of the same kind. Our age range guarantees that we understand and speak the language of different groups from 20 to 50.</p>
                </div>
              </div>
              <div className="card grdwrap" onClick={(slideIndex) => setCurrCar({ slideIndex: 4 })}>
                <div className="inner">
                  <div className="icowrp">
                    <img src={Transparency} alt="Transparency" className="ico" />
                    <img src={TransparencyOvr} alt="Transparency hover" className="ico ovr" />
                  </div>
                  <h3>Transparency</h3>
                  <p>You’ll be always aware of your project’s current state: we’ll provide you with detailed reporting and proper account management.</p>
                </div>
              </div>
              <div className="card grdwrap" onClick={(slideIndex) => setCurrCar({ slideIndex: 5 })}>
                <div className="inner">
                  <div className="icowrp">
                    <img src={Pro_bono} alt="Pro bono" className="ico" />
                    <img src={Pro_bonoOvr} alt="Pro bono hover" className="ico ovr" />
                  </div>
                  <h3>Pro bono work</h3>
                  <p>We are concerned about many social issues, especially the climate crisis and human rights. </p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};


if (document.getElementById("services")) {
    ReactDOM.render(<Services />, document.getElementById("services"));
}

export default Services;
