import React from "react";
import Loader from "./Loader";
import Tag from "./Tag";

import { CSSTransition, TransitionGroup } from "react-transition-group";

const Tags = ({ selectedTags, allTags, getSearchValue, getTagValue, search, loading }) => {
    return (
        <>
            <div className="input-wrap">
                <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Search articles"
                    onChange={getSearchValue}
                    value={search}
                />
                <img src="" alt="" />
            </div>
            <h3>Category Tags</h3>
            <TransitionGroup>
                {loading ? (
                    <CSSTransition timeout={300} classNames="loading">
                        <Loader />
                    </CSSTransition>
                ) : (
                    allTags &&
                    allTags.map(tags => {
                        return (
                            <CSSTransition
                                key={tags.id}
                                timeout={300}
                                classNames="loading"
                            >
                                <Tag
                                    tagname={tags.name}
                                    getTagValue={getTagValue}
                                    selectedTags={selectedTags}
                                />
                            </CSSTransition>
                        );
                    })
                )}
            </TransitionGroup>
        </>
    );
};

export default Tags;
