import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from "react-google-recaptcha-v3";

import LogoBig from "../../../resources/media/logo-big.svg";

const Contact = () => {
    const [form, setForm] = useState({
        name: "",
        email: "",
        message: "",
        consent: false
    });
    const [isValid, setIsValid] = useState({
        mailSent: false,
        robot: true,
        error: null,
        result: {},
        consent: false
    });

    const API_PATH = () => {
        const currentLocation = window.location.href;
        const endpoint = "/send-message";
        if (currentLocation && currentLocation.includes("blog")) {
            return currentLocation.split("/blog")[0] + endpoint;
        } else {
            return window.location.origin + endpoint;
        }
    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };

    const enableGA = (successOrNot) => {
        window.dataLayer.push({
            'event': 'formSubmission',
            'formType': 'Contact us',
            'formEvent': successOrNot
           });
      }

    const submit = event => {
        event.preventDefault();
        console.log(form);
        axios({
            method: "post",
            url: `${API_PATH()}`,
            headers: { "content-type": "application/json" },
            data: { crud: { ...form } }
        })
            .then(result => {
                setIsValid({ ...isValid, result });
                setIsValid({ ...isValid, mailSent: true });
                enableGA('form-success')
                setForm({
                    name: "",
                    email: "",
                    message: "",
                    consent: false
                });
            })
            .catch(error => {
                setIsValid({ error: error.response.data });
                console.log(error.response.data);
                enableGA('form-fail')
            });
        event.preventDefault();
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <h2>
                        Let’s create amazing work <b>together.</b>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 col-sm-8 col-xs-12">
                    <form id="contact-form" onSubmit={submit} method="POST">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="grdwrap">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Name"
                                        required={true}
                                        onChange={handleInputChange}
                                        value={form.name}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="grdwrap">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        required={true}
                                        onChange={handleInputChange}
                                        value={form.email}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="textarea grdwrap">
                                    <textarea
                                        name="message"
                                        id="message"
                                        placeholder="Message"
                                        required={true}
                                        onChange={handleInputChange}
                                        value={form.message}
                                    />
                                </div>
                                <p className="gc nomarg-btm">
                                    This site is protected by reCAPTCHA. Please
                                    see Google &nbsp;
                                    <a href="https://policies.google.com/privacy">
                                        {" "}
                                        Privacy Policy
                                    </a>{" "}
                                    &nbsp; and &nbsp;
                                    <a href="https://policies.google.com/terms">
                                        {" "}
                                        Terms of Service
                                    </a>
                                    .
                                </p>
                                <p className="gc nomarg">
                                    <label
                                        className="grdwrap"
                                        htmlFor="consent"
                                    >
                                        <input
                                            onChange={() => {
                                                setIsValid({
                                                    ...isValid,
                                                    consent: !isValid.consent
                                                });
                                                setForm({
                                                    ...form,
                                                    consent: !form.consent
                                                });
                                            }}
                                            type="checkbox"
                                            id="consent"
                                            name="consent"
                                            checked={form.consent}
                                        />
                                        <span className="consent"></span>
                                    </label>
                                    <span>
                                        I agree to my email being stored and
                                        used to receive commercial offers from
                                        MOST branding.
                                    </span>
                                </p>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <GoogleReCaptchaProvider reCaptchaKey="6Lc2XrEZAAAAAKUfG38zPpX88OkcX4soV2ITn8q1">
                                    <GoogleReCaptcha
                                        onVerify={token =>
                                            setIsValid({
                                                ...isValid,
                                                robot: false
                                            })
                                        }
                                    />
                                </GoogleReCaptchaProvider>
                                <button
                                    type="submit"
                                    name="submit"
                                    disabled={isValid.consent ? false : true}
                                >
                                    Send message
                                </button>
                                {isValid.mailSent === true ? (
                                    <p className="thank-you-message">
                                        Your message has been sent! We'll
                                        contact you soon.
                                    </p>
                                ) : (
                                    ""
                                )}
                                {isValid.error != null ? (
                                    <p className="error-message">
                                        Ouch! Something went wrong. Please try
                                        to refresh your browser or contact us
                                        directly &nbsp;
                                        <a href="mailto:hello@most-branding.com">
                                            hello@most-branding.com
                                        </a>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12 logocont">
                    <img src={LogoBig} alt="MOST branding logo" />
                </div>
            </div>
        </div>
    );
};

if (document.getElementById("contact")) {
    ReactDOM.render(<Contact />, document.getElementById("contact"));
}

export default Contact;
