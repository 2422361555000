import useWindowSize from "./useWindowSize"

const breakpoints = () => {
    const size = useWindowSize();
    if (size.width > 1200) {
        return 4

    } else if (size.width > 992) {
        return 3

    } else if (size.width > 768) {
        return 2

    } else if (size.width > 480) {
        return 1
    }
}

export default breakpoints;
