import React from "react";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import Loader from "./Loader";
import { TRUNCATE, DATE } from "../Helpers/Truncate";

const Posts = ({ blogPosts, loading, noArticles, limiter, isThereMore }) => {
    return (
        <>
            <TransitionGroup className="row all-post">
                {loading && !noArticles ? (
                    <CSSTransition timeout={300} classNames="loading">
                        <Loader />
                    </CSSTransition>
                ) : !loading && noArticles ? (
                    <CSSTransition timeout={300} classNames="loading">
                        <div className="no-result">
                            <p>No result this time.</p>
                            <p>Maybe try somehting else. 🤔</p>
                        </div>
                    </CSSTransition>
                ) : !loading && !noArticles ? (
                    blogPosts &&
                    blogPosts.map(post => {
                        return (
                            <CSSTransition
                                key={post.id}
                                timeout={300}
                                classNames="loading"
                            >
                                <div className="col-md-4 col-sm-6 col-xs-12 ">
                                    <div className="blog-card">
                                        <a href={"blog/" + post.url}>
                                            <div className="wrap">
                                                <img
                                                    src={post.imgUrl}
                                                    alt={post.title}
                                                />
                                            </div>
                                            <h3>{post.title}</h3>
                                            <div className="excerpt">
                                                {post.headline ? (
                                                    <p>
                                                        {TRUNCATE(
                                                            post.headline,
                                                            120
                                                        )}
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <span className="time">
                                                <img
                                                    src={window.location.origin + "/media/img/cal.svg"}
                                                    alt="calendar-icon"
                                                />
                                                {DATE(post.published_at, 10)}
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </CSSTransition>
                        );
                    })
                ) : (
                    ""
                )}
            </TransitionGroup>
            {!noArticles ? (
                <span
                    className={
                        "button center" + (isThereMore ? "" : " disabled")
                    }
                    onClick={limiter}
                >
                    {isThereMore ? "Load more posts" : "No more posts"}
                </span>
            ) : (
                ""
            )}
        </>
    );
};

export default Posts;
