const { Smooth } = require('./Helpers/Smooth');
Smooth();

require('./components/Nav');
require('./components/Services');
require('./components/Contact');
require('./components/Cookie');
require('./components/Blog');

// Console Hi from Gabor and Innruptive
const green = [
    'background: #000'
    , 'color: springgreen'
    , 'display: inline-block'
    , 'font-weight: bold'
].join(';');

const white = [
    'background: #000'
    , 'color: white'
    , 'display: inline-block'
    , 'font-weight: bold'
].join(';');

const lime = [
    'background: #000'
    , 'color: #acd261'
    , 'display: inline-block'
    , 'font-weight: bold'
].join(';');

const orange = [
    'background: #000'
    , 'color: #ff2d20'
    , 'display: inline-block'
    , 'font-weight: bold'
].join(';');

console.log('%c MOST branding - 2020', lime);
console.log('%c Finding the essence. Creating the impact.', white);
console.log('%c hello@most-branding.com', lime);
console.log('%c ', lime);
console.log('%c INNRUPTIVE', green);
console.log('%c Front-end solutions made with care and speed.', white);
console.log('%c Balint Apro - balint@innruptive.com', green);
console.log('%c ', lime);
console.log('%c Gabor Morvai', orange);
console.log('%c High security back-end solutions made with Laravel.', white);
console.log('%c gabor.morvai.hp@gmail.com', orange);
