import React from "react";
import ReactDOM from "react-dom";
import CookieConsent from "react-cookie-consent";

const Cookie = () => {    
    return (
        <CookieConsent
        buttonText="OKAY"
        cookieName="most-branding.com"
        style={{
          background: "#333",
          borderTop: "1px solid #2f2f2f",          
          fontSize: "14px",
          lineHeight: "16px"
        }}
        buttonStyle={{
          borderRadius: "31px",
          boxShadow: "none",
          cursor: "pointer",
          flex: "0 0 auto",
          margin: "4px",
          padding: "8px 16px",
          background: "#f2994a",
          color: "#fffff",
          textAlign: "center",
          textTransform: "uppercase",
          border: "none",
          transition: "all 0.25s ease"
        }}
      >
        This website uses cookies to enhance the user experience. This site is
        protected by reCAPTCHA and the Google <a target="_blank" style={{ color: "#f2994a", textDecoration: "none"}} href="https://policies.google.com/privacy">Privacy Policy</a> and <a target="_blank" style={{ color: "#f2994a", textDecoration: "none"}} href="https://policies.google.com/terms">Terms of Service</a>.
      </CookieConsent>
    );
};

if (document.getElementById("cookie")) {
    ReactDOM.render(<Cookie />, document.getElementById("cookie"));
}

export default Cookie;
