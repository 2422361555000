// Smooth Scrolling (with IE support)

export var smooth = () => // Code in a function to create an isolate scope
{
	var speed = 1000;
	var moving_frequency = 15; // Affects performance !
	var links = document.getElementsByTagName("a");
	var href;
	for (var i = 0; i < links.length; i++) {
		href =
			links[i].attributes.href === undefined
				? null
				: links[i].attributes.href.nodeValue.toString();
		if (href !== null && href.length > 1 && href.substr(0, 1) == "#") {
			links[i].onclick = function () {
				var element;
				var href = this.attributes.href.nodeValue.toString();
				if ((element = document.getElementById(href.substr(1)))) {
					var hop_count = speed / moving_frequency;
					var getScrollTopDocumentAtBegin = getScrollTopDocument();
					var gap =
						(getScrollTopElement(element) - getScrollTopDocumentAtBegin) /
						hop_count;

					for (var i = 1; i <= hop_count; i++) {
						(function () {
							var hop_top_position = gap * i;
							setTimeout(function () {
								window.scrollTo(
									0,
									hop_top_position + getScrollTopDocumentAtBegin
								);
							}, moving_frequency * i);
						})();
					}
				}

				return false;
			};
		}
	}

	var getScrollTopElement = function (e) {
		var top = 0;
		if (e.id === "top" ){
			while (e.offsetParent != undefined && e.offsetParent != null) {
				top += e.offsetTop - 160 + (e.clientTop != null ? e.clientTop : 0);
				e = e.offsetParent;
			}
		} else {
					while (e.offsetParent != undefined && e.offsetParent != null) {
			top += e.offsetTop - 65 + (e.clientTop != null ? e.clientTop : 0);
			e = e.offsetParent;
		}

		}

		return top;
	};

	var getScrollTopDocument = function () {
		return document.documentElement.scrollTop + document.body.scrollTop;
	};
};
